import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Accordion, Card, NavLink } from "react-bootstrap";
import styled from "styled-components";
import "../components/layout.scss";
import Layout from "../components/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const ExtraPaddingBottom = styled.div`
  padding-bottom: 30px;
`;

const FaqCard = styled(Card)`
  border: none;
`;
const FaqCardHeader = styled(Card.Header)`
  border: none;
  padding: 0px;
  padding
`;
const FaqCardBody = styled(Card.Body)`
  padding: 0px 12px 12px 15px;
  border: none;
`;

const FaqQues = styled.div`
  color: #271344;
  font-family: "Fredoka One", sans-serif;
  font-size: 16px;
`;

const FaqAns = styled.div`
  color: #271344;
  font-family: "Prompt", sans-serif;
`;

const FaqTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const AccessCourseButton = styled.button`
  background-color: #271344 !important;
  border: none !important;
  width: 60%;
  margin-left: 20% !important;
  color: white;
`;

export const query = graphql`
  {
    faq: allContentfulFaqQuestion {
      nodes {
        answer {
          answer
        }
        question
      }
    }
    static : allContentfulFaqPage {
      nodes {
        bottomHeading
        buttonText
        title
      }
    }
  }
`;

const Faq = ({ data }) => {
  let [selectedCard, setSelectedCard] = useState();
  let accordianData = data.faq.nodes;
  let staticData = data.static.nodes[0];
  let toggleCard = index => {
    selectedCard == index ? setSelectedCard(null) : setSelectedCard(index);
  };

  return (
    <Layout>
      <div id="content" className="site-content">
        <div className="content-inner">
          <div className="">
            <div className="row content-row">
              <div id="primary" className="content-area col-12">
                <main id="main" className="site-main">
                  <article
                    id="post-45"
                    className="post-45 page type-page status-publish hentry"
                  >
                    <div className="entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="45"
                        className="elementor elementor-45"
                        data-elementor-settings="[]"
                      >
                        <div className="elementor-section-wrap">
                          <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="f6893e0"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                          >
                            <div className="elementor-container elementor-column-gap-default ">
                              <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                                data-id="9f5f77a"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="4522805"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b21f"
                                        data-id="367b21f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                                            data-id="9265126"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container animation-bottom ">
                                              <div className="cms-image-single cms-animation-left-to-right ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="87"
                                                    src="../images/image-home-2.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                                        data-id="8869f2a"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                            data-id="8832270"
                                            data-element_type="widget"
                                            data-widget_type="cms_heading.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                                <h3 className="custom-heading">
                                                  <span>{staticData.title}</span>
                                                </h3>
                                              </div>{" "}
                                            </div>
                                            <ExtraPaddingBottom />
                                            <div>
                                              <Accordion>
                                                {accordianData.map(
                                                  (item, index) => {
                                                    return (
                                                      <>
                                                        <FaqCard className="faq-card responsive-width-90">
                                                          <FaqCardHeader>
                                                            <Accordion.Toggle
                                                              as={NavLink}
                                                              variant="link"
                                                              eventKey={
                                                                index + 1
                                                              }
                                                              onClick={() =>
                                                                toggleCard(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <FaqTitle>
                                                                <FaqQues>
                                                                  {" "}
                                                                  {
                                                                    item.question
                                                                  }{" "}
                                                                </FaqQues>
                                                                <FontAwesomeIcon
                                                                  icon={
                                                                    selectedCard ==
                                                                    index
                                                                      ? faMinus
                                                                      : faPlus
                                                                  }
                                                                  size="lg"
                                                                  className="text-carrot"
                                                                />
                                                              </FaqTitle>
                                                            </Accordion.Toggle>
                                                          </FaqCardHeader>
                                                          <Accordion.Collapse
                                                            eventKey={index + 1}
                                                          >
                                                            <FaqCardBody>
                                                              <FaqAns>
                                                                {
                                                                  item.answer
                                                                    .answer
                                                                }
                                                              </FaqAns>
                                                            </FaqCardBody>
                                                          </Accordion.Collapse>
                                                        </FaqCard>
                                                        <ExtraPaddingBottom />
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </Accordion>
                                            </div>
                                            <ExtraPaddingBottom />
                                            <div className="">
                                              <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                                <h3 className="custom-heading">
                                                  <span>
                                                    {staticData.bottomHeading}
                                                  </span>
                                                </h3>
                                              </div>{" "}
                                              <AccessCourseButton onClick={()=>{window.location.href= '/quiz-about'}}>
                                              {staticData.buttonText}
                                              </AccessCourseButton>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="padding-right10 elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6be929f"
                                        data-id="6be929f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="responsive-hidden elementor-element elementor-element-97fbc33 cms-animation-bottom-to-top2 elementor-widget elementor-widget-cms_image_single"
                                            data-id="97fbc33"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container ">
                                              <div className="cms-image-single cms-animation-bottom-to-top2 ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="82"
                                                    src="../images/image-home-3.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                            <ExtraPaddingBottom />
                                            <ExtraPaddingBottom />
                                            <ExtraPaddingBottom />
                                            <ExtraPaddingBottom />
                                            <ExtraPaddingBottom />
                                            <ExtraPaddingBottom />
                                            <ExtraPaddingBottom />
                                            <ExtraPaddingBottom />
                                            <ExtraPaddingBottom />
                                            <ExtraPaddingBottom />
                                            <div className="elementor-widget-container animation-bottom  ">
                                              <div className="cms-image-single cms-animation-left-to-right ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="82"
                                                    src="../images/image-home-1.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Faq;
